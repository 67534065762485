import React, { Component} from 'react';

import { DataGrid } from '@material-ui/data-grid';

// @ts-ignore
import {compose} from 'react-recompose';

type DataTableProps = {
  data: any;
  columns: any;
};
type DataTableState = {};

class DataTable extends Component<DataTableProps, DataTableState> {
  render() {
    const {data, columns} = this.props;

    return (
    <div style={{ height: 400, width: '100%', wordWrap: 'break-word', overflowWrap: 'break-word'}}>
      <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          disableSelectionOnClick
        />
    </div>
    );
  }
}

export default compose()(DataTable);
