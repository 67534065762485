import { AppBar, Toolbar, Button, Typography, Theme, withStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { Component } from 'react';
// @ts-ignore
import { compose } from 'react-recompose';
import { Link } from 'react-router-dom';

import Help from './help';

const styles = ({ spacing, breakpoints }: Theme) => ({
  header: {
    backgroundColor: '#0D1C3D',
  },
  headerButton: {
    position: 'fixed',
    top: spacing(-0.7),
    right: spacing(0.5),
    color: '#f50057',
    [breakpoints.down('xs')]: {
      top: spacing(-0.5),
      right: spacing(1),
    },
  },
  toolBar: {
    paddingLeft: spacing(1),
  },
  title: {
    fontSize: "1.5em",
    color: "white"
  },
  helpIcon: {
    fontSize: '4.5em',
    color: 'white',
    [breakpoints.down('xs')]: {
      fontSize: '3.5em',
    },
  },
  image: {
    height: "6vh",
    backgroundColor: "white",
    marginRight: spacing(1),
  }
});

type AppHeaderProps = {
  classes: any;
};
type AppHeaderState = {
  showHelp?: boolean;
};

class AppHeader extends Component<AppHeaderProps, AppHeaderState> {
  constructor(props: any) {
    super(props);

    this.state = {
      showHelp: localStorage.getItem('DontShowHelp') !== 'true',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    this.setState({
      showHelp: !this.state.showHelp,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <AppBar position="static" className={ classes.header }>
        <Toolbar className={ classes.toolBar }>
          <Button color="inherit" component={ Link } to="/">
            <img className={ classes.image } src="/images/hcss_logo.png" alt="Logo HCSS"/>
            <Typography className={ classes.title } variant="h1"> Climate Security Risk Monitor </Typography>
          </Button>
        </Toolbar>

        <Button onClick={ this.handleChange } className={ classes.headerButton }>
          <HelpOutlineIcon color="secondary" aria-label="add" className={ classes.helpIcon } />
        </Button>
        <Help handleChange={ this.handleChange } showModal={ this.state.showHelp } />
      </AppBar>
    );
  }
}

// @ts-ignore
export default compose(withStyles(styles))(AppHeader);
