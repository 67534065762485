import { withStyles,
        Typography,
        Theme,
        TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Component, Fragment } from 'react';
// @ts-ignore
import { compose } from 'react-recompose';
import { withRouter } from 'react-router-dom';

import ErrorSnackbar from '../components/errorSnackbar';
import InfoSnackbar from '../components/infoSnackbar';
import LoadingBar from '../components/loadingBar';
import BulletPlot from '../components/bulletPlot'

import { ModelService } from '../services';
import { Country, BulletPlotData } from '../model'

const styles = ({spacing}: Theme) => ({
  formControl: {
    width: "400px",
    marginTop: spacing(1),
    marginBottom: spacing(2),
  }
});

type CountryViewerProps = {
  location: any
  classes: any;
  history: any
};

type CountryViewerState = {
  countries: Country[];
  countrySelected: Country;
  data: BulletPlotData[];

  service: ModelService;

  loading: boolean;
  success: string;
  error: string;
};

class CountryViewer extends Component<CountryViewerProps, CountryViewerState> {
  constructor(props: any) {
    super(props);

    this.state = {
      countries: [],
      countrySelected: null as any,
      data: [],

      service: ModelService.getInstance(),

      loading: true,
      success: '',
      error: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    let countryRequested = this.props.location.pathname.split("/")[2] || "NLD"
    this.getCountries()
    .then(countries => {
      this.setState({
        countries: countries.sort((a, b) => a.name > b.name ? 1 : -1),
        countrySelected: countries.find(country => country.iso3c === countryRequested) || null as any,
        loading: false
      }, () => {
        if(countryRequested) {
          this.getPlotData(this.state.countrySelected)
        }
      })
    })
  }

  async getCountries() {
    try {
      return await this.state.service.getCountries()
    }
    catch {
      this.setState({
        error: "Could not talk with backend"
      })

      return []
    }
  }

  getPlotData(country: Country) {
    this.state.service.getBulletPlotData(country)
    .then(data => {
      this.setState({
        countrySelected: country,
        data: data
      })
    })
  }

  handleChange(country: Country | null) {
    if(country) {
      this.getPlotData(country)
      this.routingFunction(country.iso3c)
    }else {
      this.setState({
        countrySelected: null as any,
        data: []
      })
    }
  }

  routingFunction = (param: string) => {
    this.props.history.push({
      pathname: "/countryviewer/" + param,
      state: param
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        { this.state.countries.length > 0 && (
          <Fragment>
            <Typography variant="h4">Country View</Typography>

            { /* inout for country */ }
            <Autocomplete
              id="country-selection"
              value={ this.state.countrySelected }
              onChange={(event: any, country: Country | null) => { this.handleChange(country) }}
              options={ this.state.countries }
              getOptionLabel={ (option) => option.name }
              className={ classes.formControl }
              renderInput={(params) => <TextField {...params} label="Country Selection" variant="outlined" />}
            />
          </Fragment>
        )}

        {this.state.countrySelected && (
          <Fragment>
            <Typography variant="h5">{ this.state.countrySelected.name }</Typography>

            <BulletPlot
              data={ this.state.data}
              id={ this.state.countrySelected.iso3c }
            ></BulletPlot>
          </Fragment>
        )}

        {/* Flag based display of loadingbar */}
        {this.state.loading && <LoadingBar />}

        {/* Flag based display of error snackbar */}
        {this.state.error.length > 0 && (
          <ErrorSnackbar onClose={() => this.setState({ error: '' })} message={this.state.error} />
        )}

        {/* Flag based display of info snackbar */}
        {this.state.success.length > 0 && (
          <InfoSnackbar
            onClose={ () => this.setState({ success: '' }) }
            message={ this.state.success }
          />
        )}
      </Fragment>
    );
  }
}

// @ts-ignore
export default compose(withRouter, withStyles(styles))(CountryViewer);
