import { CssBaseline, withStyles, Theme } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import MonitorManager from '../pages/monitorManager';
import MonitorViewer from '../pages/monitorViewer';
import CausalViewer from '../pages/causalViewer';
import CountryViewer from '../pages/countryViewer';

import AppHeader from './appHeader';
import Footer from './footer'

const styles = ({ spacing, breakpoints }: Theme) => ({
  main: {
    padding: spacing(2),
    [breakpoints.down('xs')]: {
      padding: spacing(0.5),
    },
  },
});

const App = ({ classes }: { classes: any }) => (
  <Fragment>
    <CssBaseline />

    <AppHeader />

    <main className={ classes.main }>
      <Route exact path="/" component={ MonitorManager } />
      <Route exact path="/hazardviewer/" component={ MonitorViewer } />
      <Route exact path="/hazardviewer/:id" component={ MonitorViewer } />
      <Route exact path="/causalmonitor/:id" component={ CausalViewer } />
      <Route exact path="/countryviewer/" component={ CountryViewer } />
      <Route exact path="/countryviewer/:id" component={ CountryViewer } />
    </main>

    <Footer/>
  </Fragment>
);

export default withStyles(styles)(App);
