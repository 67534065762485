import {
    Typography,
    Grid,
    Theme,
    withStyles,
    Card,
    Box } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import {Link, withRouter} from 'react-router-dom';
// @ts-ignore
import {compose} from 'react-recompose';

import ErrorSnackbar from '../components/errorSnackbar';
import InfoSnackbar from '../components/infoSnackbar';
import LoadingBar from '../components/loadingBar';
import ModelCard from '../components/modelCard';

const styles = ({spacing, breakpoints}: Theme) => ({
  introText: {
    fontSize: '1.5em',
    [breakpoints.down('xs')]: {
      fontSize: '1.2em',
    }
  },
  text: {
    margin: spacing(1),
    padding: spacing(1),
    textAlign: 'center',
  },
  row: {
    marginTop: '2%',
  },
  link: {
    textDecoration: 'none',
  },
  label: {
    color: 'black!important'
  }
});

type Model = {
  id: string;
  title: string;
  description: string;
  link: string;
  imagePath: string;
}

// predictive models
const models: Model[] = [
  { id: "Country_Viewer", title: "Country Viewer", description: "", link: "countryviewer", imagePath: "/images/icons/world_blue.png"},
  { id: "Hazard_Viewer", title: "Hazard Viewer", description: "", link: "hazardviewer", imagePath: "/images/icons/wave blue.png"},
]

// causal models
const causal_models:Model[] = [
  { id: "Social_Tension", title: "Social Tensions Causal", description: "", link: "causalmonitor", imagePath: "/images/icons/social_tensions_blue.png"},
  { id: "Forced_Migration", title: "Forced Migration Causal", description: "", link: "causalmonitor", imagePath: "/images/icons/migration_dark_blue.png"},
]

// dev models
const dev_causal_models:Model[] = [
  { id: "Inequality_Causal", title: "Inequality Causal", description: "", link: "", imagePath: "/images/icons/equality.png"},
  { id: "Destabilised_Institutions_Causal",title: "Destabilised Instutions Causal", description: "", link: "", imagePath: "/images/icons/institutions.png"},
  { id: "Resource_Scarcity_Causal", title: "Resource Sparcity Causal", description: "", link: "", imagePath: "/images/icons/resource_scarcity.png"}
]

type MonitorManagerProps = {
  classes: any;
};

type MonitorManagerState = {
  loading: boolean;
  success: string;
  error: string;
};

class MonitorManager extends Component<MonitorManagerProps, MonitorManagerState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      success: '',
      error: '',
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
         <Card className={ classes.text}>
          <Typography className={ classes.introText }>This monitor interactively visualizes the HCSS Climate Security Risk Methodology. Exploration of the data contained within the monitor is intended to support policy makers and researchers in understanding insecurity relating to climate change. The monitor is organized into tabs to facilitate cross country, and hazard analysis. The data underlying the monitor and the Climate Security Risk Methodology are also accessible.</Typography>
        </Card>
        <Box>
            { /* Predictive Modelling */ }
            <Grid className={ classes.row } container justifyContent="center" spacing={ 4 }>
              {models.map((element:Model) => (
                <Grid xs="auto" key={ element.id } item>
                  <Link className={ classes.link } to={ `/${ element.link }` }>
                    <ModelCard
                      modId={ element.id }
                      modTitle={ element.title }
                      description={ element.description }
                      imagePath={ element.imagePath }
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>

            {/* Causal Monitors */}
            <Grid className={ classes.row } container justifyContent="center" spacing={ 4 }>
              {causal_models.map((element:Model) => (
                <Grid xs="auto" key={ element.id } item>
                  <Link className={ classes.link } to={ `/${ element.link }/${ element.id }` }>
                    <ModelCard
                      modId={ element.id }
                      modTitle={ element.title }
                      description={ element.description }
                      imagePath={ element.imagePath }
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>

            {/* dev Monitors (don't have a link yet) */}
            <Grid className={ classes.row } container justifyContent="center" spacing={ 4 }>
              {dev_causal_models.map((element:Model) => (
                <Grid xs="auto" key={ element.id } item>
                    <ModelCard
                      modId={ element.id }
                      modTitle={ element.title }
                      description={ element.description }
                      imagePath={ element.imagePath }
                    ></ModelCard>
                </Grid>
                ))}
            </Grid>
        </Box>

        {/* Flag based display of error snackbar */}
        {this.state.error.length > 0 && (
          <ErrorSnackbar onClose={ () => this.setState({ error: '' }) } message={ this.state.error } />
        )}

        {/* Flag based display of loadingbar */}
        {this.state.loading && <LoadingBar />}

        {/* Flag based display of info snackbar */}
        {this.state.success.length > 0 && (
          <InfoSnackbar
            onClose={ () => this.setState({ success: '' }) }
            message={ this.state.success }
          />
        )}
      </Fragment>
    );
  }
}

// @ts-ignore
export default compose(withRouter, withStyles(styles))(MonitorManager);
