import { withStyles, CircularProgress, Theme } from '@material-ui/core';
import React from 'react';
// @ts-ignore
import { compose } from 'react-recompose';
import { withRouter } from 'react-router-dom';

const styles = ({spacing}: Theme) => ({
  loadingBar: {
    position: 'fixed',
    top: spacing(10),
    fontSize: 100,
    marginTop: spacing(10),
    marginLeft: '50%',
    zIndex: 100000,
    color: '#0D1C3D',
  },
});

const LoadingBar = ({ classes }: { classes: any }) => (
  <div className={ classes.root }>
    <CircularProgress className={ classes.loadingBar } size={ 100 } />
  </div>
);

// @ts-ignore
export default compose(withRouter, withStyles(styles))(LoadingBar);