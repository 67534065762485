import {
  withStyles,
  Card,
  CardContent,
  CardActions,
  Modal,
  Button,
  Typography,
  Theme,
  Tabs,
  Tab,
  AppBar,
  Box,
  Switch
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AssessmentIcon from '@material-ui/icons/Assessment';
import React, { Component, Fragment } from 'react';
// @ts-ignore
import { compose } from 'react-recompose';
import { withRouter } from 'react-router-dom';

import DataSources from './datasources'

const styles = ({ spacing }: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalCard: {
    width: '90%',
    maxWidth: 1000,
    height: '85%',
    overflowY: 'scroll',
  },
  modalCardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  textCenter: {
    margin: 'auto',
  },
  images: {
    width: '100%',
  },
  links: {
    textDecoration: 'none',
    color: 'black',
  },
  header: {
    marginTop: spacing(2),
  },
  tabView: {
    backgroundColor: "#0D1C3D "
  }
});

type HelpProps = {
  showModal?: boolean;
  handleChange: any;
  classes: any;
};
type HelpState = {
  showModal?: boolean;
  showHelpAtEveryStart?: boolean;
  tab: number
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

class Help extends Component<HelpProps, HelpState> {
  constructor(props: any) {
    super(props);

    this.state = {
      showModal: false,
      showHelpAtEveryStart: localStorage.getItem('DontShowHelp') === 'true',
      tab: 0
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this)
  }

  componentDidMount() {
    this.setState({
      showModal: this.props.showModal
    })
  }

  componentDidUpdate() {
    if (this.state.showModal !== this.props.showModal) {
      this.setState({ showModal: this.props.showModal });
    }
  }

  handleChange() {
    const parentHandler = this.props.handleChange;
    this.setState(
        {
          showModal: !this.state.showModal,
        },
        parentHandler,
    );
  }

  handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    this.setState({
      tab: newValue
    })
  }

  handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ showHelpAtEveryStart: event.target.checked });
    localStorage.setItem('DontShowHelp', event.target.checked.toString());
  }

  render() {
    const { classes } = this.props;
    const APP_VERSION = process.env.REACT_APP_VERSION;

    return (
      <Fragment>
        {this.state.showModal && (
          <Modal className={ classes.modal } onClose={ this.handleChange } open>
            <Card className={`${ classes.modalCard }`}>
              <AppBar position='static' className={ classes.tabView}>
                <Tabs value={ this.state.tab } onChange={ this.handleTabChange } aria-label="help tab view" textColor="inherit">
                  <Tab label="General Remarks" id="help-tab-0" aria-controls="help-tab-0"/>
                  <Tab label="Causal Graphs" id="help-tab-1" aria-controls="help-tab-1"/>
                </Tabs>
              </AppBar>

                { /* Tab 1 */ }
                <TabPanel value={ this.state.tab } index={ 0 }>
                  <CardContent className={ classes.modalCardContent}>
                  <Typography variant="h6">How is Climate Risk relevant to conflict?</Typography>
                  <Typography>Climate change acts as a threat multiplier by triggering or aggravating existing cleavages within societies. More specifically, when climate change overburdens a countries’ governmental capacity, societies become more vulnerable to social or political instability. </Typography>

                  <Typography className={ classes.header } variant="h6">How does this monitor assist?</Typography>
                  <Typography>Using this new risk assessment tool, decision-makers on the global, national and regional level can identify specific targets to prevent, mitigate and/or avert the security impact and human suffering of climate-related extreme weather events. Additionally, this methodology can be utilized for tracking the progress and effectiveness of disaster-risk reduction measures or strategies.  </Typography>

                  <Typography className={ classes.header } variant="h6">How is the Climate Security Risk calculated? </Typography>
                  <Typography>Climate security risk is calculated as a function of probability and potential impact. The probability of a hazard is determined by the onset of the natural event and the vulnerability of a country to that specific event. Probability is further broken down into into composite elements: natural hazard and vulnerability.</Typography>
                  <Typography>Potential impact of a climate-related hazard on a society – or the consequences in terms of loss, damage, and adverse effects within a country – are determined by the exposure of susceptible elements. Impact is broken down into susceptibility and vulnerability.</Typography>

                  <Typography className={ classes.header } variant="h6">Access to the full report</Typography>
                  <Typography>Please refer to the Climate Security Risk Report for more detail.</Typography>
                  <Typography>Access the report here: </Typography>
                  <a className={ classes.links } target="_blank" href="https://hcss.nl/report/climate-security-assessment-a-methodology-and-assessment-of-the-nexus-between-climate-hazards-and-security-of-nations-and-regions/" rel="noreferrer">
                    <Button variant="contained" endIcon={<AssessmentIcon />}>
                      Report
                    </Button>
                  </a>

                  <Typography className={ classes.header } variant="h6">Data Sources</Typography>
                  <DataSources/>

                  <Typography className={ classes.header } variant="h6">About the app</Typography>
                  <Typography>App version: { APP_VERSION }</Typography>

                  <Typography className={ classes.header } variant="h6">Developing Organization</Typography>
                  <a href="https://hcss.nl/the-hcss-datalab/" target="_blank" rel="noreferrer" >
                    <img
                      className={ classes.images }
                      src={`${ window.location.origin }/images/hcss_datalab.PNG`}
                      alt="HCSS Data Lab"
                    />
                  </a>

                  <Typography className={ classes.header } variant="h6">Credits</Typography>
                  <Typography>Credit to SwissRe for the provision of Global Insurance Premium data.</Typography>
                  <Typography>This Climate Security Risk Methodology and Assessment was coordinated with the International Military Committee on Climate and Security, sponsored by HCSS and co-sponsored by the Luxembourg Government, and shouldered by the Water, Peace & Security (WPS) partnership and Progress WP6. </Typography>
                  </CardContent>
              </TabPanel>

              { /* Tab 2 */ }
              <TabPanel value={this.state.tab} index={1}>
                <CardContent className={ classes.modalCardContent}>
                  <Typography variant="h6">What is Causal Modelling?</Typography>
                  <Typography>We regularly hear that the effects of climate change lead to all kind of insecurities, such as social tensions and migration. But how the natural hazards as a result of climate change exactly lead to these insecurities and how to quantify their causal effect remains unknown. With causal modeling we try to uncover the hidden dynamics and pathways that lead to these insecurities and estimate the causal effect these climatological variables have on corresponding insecurities. </Typography>
                  <Typography variant="h6">Causal Graphs</Typography>
                  <Typography>For each insecurity a causal graph has been extracted that connects (some of) the natural hazards to insecurities in causal terms. That means that advanced statistical techniques are applied to datasets to extract which variables have a causal effect on one another displayed by a directed arrow. Sometimes a variables has not a direct effect on another variable, but only through other variables. The entire sequence of arrows from cause to effect is denoted as the causal pathway between two variables. You can click on each node to have a more detailed description of what that variable means.  </Typography>
                  <Typography variant="h6">Causal Effects</Typography>
                  <Typography>Besides extracting the causal relations between variables, you can select a cause and effect variable. For the available combinations, the causal pathways that start with the cause and end at the effect are displayed by the subgraph in the bottom left corner. Using causal and statistical inference techniques the causal effect of the cause variable on the effect variable has been estimated. These can be understood as the effect that one unit change in the cause variable induces in the effect variable taking into account any confounding relations. The causal effects are scaled and therefore should be interpretated relatively to other causal effects. When the effect is green, it has a negative effect on the insecurity meaning an increase in the selected cause leads to a decrease in the insecurity (e.g. less conflict/migration). A red effect means that an increase in the cause variable leads to an increase in the insecurity variable.  </Typography>
                </CardContent>
              </TabPanel>

              { /* Action */ }
              <CardActions>
                <Button size="small" onClick={ this.handleChange }>
                  <ClearIcon />
                  Close
                </Button>

                <Switch
                  checked={this.state.showHelpAtEveryStart}
                  onChange={this.handleSwitchChange}
                  name="showHelpAtEveryStart"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                Do not display help at every start
              </CardActions>
            </Card>
          </Modal>
        )}
      </Fragment>
    );
  }
}

// @ts-ignore
export default compose(withRouter, withStyles(styles))(Help);
