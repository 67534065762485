import { withStyles, Theme } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

// @ts-ignore
import { compose } from 'react-recompose';

import ErrorSnackbar from '../components/errorSnackbar';
import LoadingBar from '../components/loadingBar';

import { ModelService } from '../services';
import { DataSource } from '../model'
import DataTable from './dataTable'

const styles = ({ spacing }: Theme) => ({
});

type DataSourcesProps = {
  classes: any;
};

type DataSourcesState = {
  service: ModelService;
  data: DataSource[];

  loading: boolean;
  success: string;
  error: string;
};

const columns = [
  {title: 'Variable', field: 'Variable', width: 300},
  {title: 'Source', field: 'Source', width: 150},
  {title: 'Date', field: 'Date', width: 100},
  {title: 'Link', field: 'Link', width: 400},
];

class DataSources extends Component<DataSourcesProps, DataSourcesState> {
  constructor(props: any) {
    super(props);

    this.state = {
      service: ModelService.getInstance(),
      data: [],

      loading: false,
      success: '',
      error: ''
    };
  }

  componentDidMount() {
    let that = this

    this.getDataSources()
    .then(data =>
      that.setState({
        data: data
      })
    )
  }

  async getDataSources() {
    try {
      return await this.state.service.getDataSources()
    }
    catch {
      this.setState({
        error: "Could not talk with backend"
      })

      return []
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {this.state.data.length > 0 && (
          <DataTable
            data={ this.state.data }
            columns={ columns }
          />
        )}

        {/* Flag based display of loadingbar */}
        {this.state.loading && <LoadingBar />}

        {/* Flag based display of error snackbar */}
        {this.state.error.length > 0 && (
          <ErrorSnackbar onClose={() => this.setState({ error: '' })} message={this.state.error} />
        )}
      </Fragment>
    )
  }
}

// @ts-ignore
export default compose(withRouter, withStyles(styles))(DataSources);
