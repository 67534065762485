import {
    Typography,
    withStyles,
  } from '@material-ui/core';
  import React, { Fragment } from 'react';
  // @ts-ignore
  import { compose } from 'react-recompose';
  
  import variableExplanation from '../data/variable_explanation.json'

  const styles = () => ({

  });
  
  const NodeInformation = ({
    data,
    classes,
  }: {
    data: string;
    classes: any;
  }) => {
    const explanation = variableExplanation.find(element => element.variable === data);

    return (
    <Fragment>
      <Typography gutterBottom variant="h6" component="h2">Variable Definition</Typography>
      <Typography component="p">
          { explanation?.explanation }
      </Typography>
    </Fragment>
  )
  }
  
  export default compose(
      withStyles(styles),
  )(NodeInformation);