import { withStyles,
        Typography,
        Theme,
        FormControl,
        TextField,
        Grid,
        Select,
        InputLabel,
        MenuItem,
        Card,
        CardContent } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
// @ts-ignore
import { compose } from 'react-recompose';
import { withRouter } from 'react-router-dom';

import ErrorSnackbar from '../components/errorSnackbar';
import InfoSnackbar from '../components/infoSnackbar';
import LoadingBar from '../components/loadingBar';
import NetworkPlot from '../components/networkPlot';
import NodeInformation from '../components/nodeInformation';

import { ModelService } from '../services';
import { CausalData, Option, Node } from '../model'
import variableExplanation from '../data/variable_explanation.json'

const styles = ({ spacing }: Theme) => ({
  formControl: {
    width: "100%",
    marginBottom: spacing(2),
  },
  text: {
    marginBottom: spacing(1),
  },
  card: {
    marginBottom: spacing(2),
  },
  positivetreatment: {
    backgroundColor: "#AE1917",
    color: "white"
  },
  negativetreatment: {
    backgroundColor: "#4E8341",
    color: "white"
  },
  outcome: {
    backgroundColor: "#1a3761",
    color: "white"
  }
});

type CausalViewerProps = {
  classes: any;
  location: any;
};

type CausalViewerState = {
  monitorId: string;
  monitorReadable: string;
  description: string
  treatment: Node[];
  treatment_selected: string;
  outcome: Node[];
  outcome_selected: string;
  networkData: CausalData;
  option: Option;
  selectedNode: string;
  selectedEdge: string;

  service: ModelService;

  loading: boolean;
  success: string;
  error: string;
};

class CausalViewer extends Component<CausalViewerProps, CausalViewerState> {
  constructor(props: CausalViewerProps) {
    super(props);

    this.state = {
      monitorId: '',
      monitorReadable: '',
      description: '',
      networkData: {} as CausalData,
      treatment: [],
      treatment_selected: '',
      outcome: [],
      outcome_selected: '',
      option: {} as Option,
      selectedNode: '',
      selectedEdge: '',

      service: ModelService.getInstance(),

      loading: false,
      success: '',
      error: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateOption = this.updateOption.bind(this);
    this.onNetworkClick = this.onNetworkClick.bind(this);
  }

  componentDidMount = () => {
    let monitorId = this.props.location.pathname.split("/")[2]
    let monitorReadable = monitorId.replaceAll("_", " ")      // create printable monitor name based on ID
    const explanation = variableExplanation.find(element => element.variable === monitorId);

    this.setState({
      monitorId: monitorId,
      monitorReadable: monitorReadable,
      description: explanation?.explanation || ""
    }, () => {
      this.getMonitorData()
    })
  }

  getNode(id: string, networkData: CausalData): Node {
    return networkData.nodes.find(node => id === node.id) || { id: "", case: "", color: "", label: "", level: 0, hidden: false}
  }

  async getMonitorData() {
    let networkData = await this.state.service.getNetworkData(this.state.monitorId)
    let treatment = Array.from(new Set(networkData.options.map(option => this.getNode(option.treatment, networkData))))
    let outcome = Array.from(new Set(networkData.options.map(option => this.getNode(option.outcome, networkData))))

    this.setState({
      networkData: networkData,
      treatment: treatment,
      outcome: outcome,

      loading: false,
    });
  }

  updateOption() {
    if(this.state.outcome_selected && this.state.treatment_selected) {
      let option = this.state.networkData.options.find(opt => opt.outcome === this.state.outcome_selected && opt.treatment == this.state.treatment_selected) as Option
      this.setState({
        option: option
      })
    }
  }

  handleChange(evt: React.ChangeEvent<{ value: unknown }>) {
    const target = evt.target
    // @ts-ignore
    const name = evt.target.name + "_selected"
    let value = target.value

    // @ts-ignore
    this.setState({ [name]: value }, () => { this.updateOption() })
  }

  onNetworkClick = (element: string, value: string) => {
    if(value) {
      // @ts-ignore
      this.setState({[element]: value})
    }
  }

  render() {
    const { classes } = this.props;
    let causal_effect = ""
    let causal_effect_color = ""

    // display causal effect when present
    if(this.state.option.causal_effect && !isNaN(this.state.option.causal_effect)) {
      let ce = Number(this.state.option.causal_effect)
      causal_effect = ": " + ce.toFixed(2)

      if(ce > 0) {
        causal_effect_color = classes.positivetreatment
      } else {
        causal_effect_color = classes.negativetreatment
      }
    }

    return (
      <Fragment>
        {this.state.networkData.edges && this.state.networkData.edges.length > 0 ? (
          // monitor data present
          <Fragment>
            <Typography variant="h4">{ this.state.monitorReadable }</Typography>
            <Typography className={ classes.text }> { "Please refer to the help for further information regarding the causal models. " + this.state.description } </Typography>

            { /* Dropdown selection for filter */ }
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 } sm={ 4 }>
                <Card className={ classes.card }>
                  <CardContent>
                    <NodeInformation data={ this.state.selectedNode }/>
                  </CardContent>
                </Card>

                <Card className={ classes.card }>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">Cause Selection</Typography>
                    <FormControl className={ classes.formControl }>
                      <InputLabel id="treatment-label" className={ causal_effect_color }>Cause</InputLabel>
                      <Select
                          labelId="treatment-label"
                          id="treatment"
                          name="treatment"
                          value={ this.state.treatment_selected }
                          onChange={ this.handleChange }
                        >
                        {this.state.treatment.map((treatment, index) => (
                          <MenuItem key={ index } value={ treatment.id }>{ treatment.label }</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                      {this.state.treatment_selected && (
                        <FormControl className={ classes.formControl }>
                          <InputLabel id="outcome-label" className={ classes.outcome }>Effect</InputLabel>
                            <Select
                                labelId="outcome-label"
                                id="outcome"
                                name="outcome"
                                value={ this.state.outcome_selected }
                                onChange={ this.handleChange }
                              >
                              {this.state.outcome.map((outcome, index) => (
                                <MenuItem key={ index } value={ outcome.id }>{ outcome.label }</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                      )}
                    </CardContent>
                  </Card>

                  <Card className={ classes.card }>
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">Causal Paths { causal_effect } </Typography>
                      <NetworkPlot
                        data={ this.state.networkData }
                        option={ this.state.option }
                        highlightPath={ true }
                        onClick={ this.onNetworkClick }
                        height="35vh"
                      />
                    </CardContent>
                  </Card>
              </Grid>

              <Grid item xs={ 12 } sm={ 8 }>
                <NetworkPlot
                  data={ this.state.networkData }
                  option={ this.state.option }
                  highlightPath={ false }
                  onClick={ this.onNetworkClick }
                  height="80vh"
                />
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          // no monitor data could be found
          !this.state.loading && (
            <Typography variant="subtitle1">No monitor data could be loaded</Typography>
          )
        )}

        {/* Flag based display of loadingbar */}
        {this.state.loading && <LoadingBar />}

        {/* Flag based display of error snackbar */}
        {this.state.error.length > 0 && (
          <ErrorSnackbar onClose={() => this.setState({ error: '' })} message={ this.state.error } />
        )}

        {/* Flag based display of info snackbar */}
        {this.state.success.length > 0 && (
          <InfoSnackbar
            onClose={ () => this.setState({ success: '' }) }
            message={ this.state.success }
          />
        )}
      </Fragment>
    );
  }
}

// @ts-ignore
export default compose(withRouter, withStyles(styles))(CausalViewer);
