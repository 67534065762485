import React, { useEffect, useState } from 'react';
import { ResponsiveBullet } from '@nivo/bullet'
import { BasicTooltip } from '@nivo/tooltip'
import { animated, to } from '@react-spring/web'

const CustomTooltip = ({ v0, v1, color }) => {
  return (
    v0 ? (
      <BasicTooltip
        id={
          color === "#AE1917" ? (
            <strong style={{ color: color }}>Regional Average (RA): { Number(v0).toFixed(2) }</strong>
          ) : (
            <strong style={{ color: color }}>Country Hazard Value (CHV): { Number(v0).toFixed(2) }</strong>
          )
        }
        enableChip={ true }
        color={ color }
      />
  ) : (
    <></>
  )
  )
}

const CustomMarker = ({
  animatedProps: { transform, x, y1, y2 },
  data,
  color,
  value,
  onMouseEnter,
  onMouseMove,
  onMouseLeave,
  onClick,
}) => {
  return (
    <g>
      <animated.line
        transform={transform}
        x1={x}
        x2={x}
        y1={y1}
        y2={y2}
        fill="none"
        stroke={color}
        strokeWidth="6"
        onMouseMove={event => onMouseMove(data, event)}
        onMouseEnter={event => onMouseEnter(data, event)}
        onMouseLeave={event => onMouseLeave(data, event)}
        onClick={event => onClick(data, event)}
      />

      <text
        transform={`translate(30, ${(100-value)*3})`}
      >
      <tspan
        fontStyle="italic"
        fill={color}
      >
        RA - { value.toFixed(2) }
      </tspan>
    </text>
    </g>
  )
}

const CustomMeasure = ({
  animatedProps: { x, y, width, height, color },
  borderColor,
  borderWidth,
  data,
  onMouseEnter,
  onMouseMove,
  onMouseLeave,
  onClick,
}) => {
  return (
    <g>
       <animated.rect
          x={x}
          y={y}
          width={to(width, value => Math.max(value, 0))}
          height={to(height, value => Math.max(value, 0))}
          fill={color}
          strokeWidth={borderWidth}
          stroke={borderColor}
          onMouseMove={event => onMouseMove(data, event)}
          onMouseEnter={event => onMouseEnter(data, event)}
          onMouseLeave={event => onMouseLeave(data, event)}
          onClick={event => onClick(data, event)}
      />

    <text
      transform={`translate(20, ${(230)}) rotate(90)`}
    >
      <tspan
        fontStyle="italic"
        fill="white"
      >
        CHV - { Number(data.v1).toFixed(2) }
      </tspan>
    </text>
    </g>
  )
}

const BulletPlot = ({
    id,
    data
  }) => {
    const [getwidth, setWidth] = useState(window.innerWidth)
    const breakpoint = 1200

    useEffect(() => {
      window.addEventListener('resize', () => setWidth(window.innerWidth))
    }
    , [])

  return (
    getwidth > breakpoint ? (
      <div style={{ height: 400 }}>
        <ResponsiveBullet
          data={data.map(d => ({
            ...d,
            title: (
              <g>
                <text dy={ -17 }>
                  <tspan
                    style={{
                      fill: '#000',
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    { d.id.replace("_", " ") }
                  </tspan>
                </text>

                <image
                  x={ 30 }
                  y={ -10 }
                  width={ 50 }
                  height={ 40 }
                  href={ `/images/icons/${ d.id }.png` }
                />
              </g>
            ),
          }))}
          layout="vertical"
          margin={ { top: 70, right: 50, bottom: 10, left: 20 } }
          spacing={ 140 }
          tooltip={ CustomTooltip }
          rangeColors={ ['#efefef'] }
          measureColors={ ['#1a3761'] }
          markerSize={ 1 }
          markerColors={ "#AE1917" }
          titleAlign="start"
          titleOffsetY={ -35 }
          titleOffsetX={ -50 }
          markerComponent={ CustomMarker }
          measureComponent={ CustomMeasure }
        />
      </div>
    ) : (
      <div style={{ height: 400 }}>
        <ResponsiveBullet
          data={data.map(d => ({
            ...d,
            title: (
              <g>
                <image
                  x={ 30 }
                  y={ -10 }
                  width={ 50 }
                  height={ 40 }
                  href={ `/images/icons/${ d.id }.png` }
                />
              </g>
            ),
          }))}
          layout="vertical"
          minValue={ 0 }
          maxValue={ 100 }
          margin={ { top: 70, right: 50, bottom: 10, left: 20 } }
          spacing={ 40 }
          tooltip={ CustomTooltip }
          rangeColors={ ['#efefef'] }
          measureColors={ ['#1a3761'] }
          markerSize={ 1 }
          markerColors={ "#AE1917" }
          titleAlign="start"
          titleOffsetY={ -35 }
          titleOffsetX={ -50 }
          markerComponent={ CustomMarker }
          measureComponent={ CustomMeasure }
        />
      </div>
    )
  )
}

export default (BulletPlot);
