import { withStyles, Snackbar, SnackbarContent, IconButton, Theme } from '@material-ui/core';
import { Error as ErrorIcon, Close as CloseIcon } from '@material-ui/icons';
import React from 'react';
// @ts-ignore
import { compose, withState } from 'react-recompose';
import {v4 as uuidv4} from 'uuid';

const styles = ({ spacing, palette }: Theme) => ({
  snackbarContent: {
    backgroundColor: palette.error.dark,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: spacing(1),
  },
});
const AUTO_HIDE_DURATION = 6000;

const ErrorSnackbar = ({
  id,
  message,
  onClose,
  classes,
}: {
  id: string;
  message: string;
  onClose: any;
  classes: any;
}) => (
  <Snackbar open autoHideDuration={ AUTO_HIDE_DURATION } onClose={ onClose }>
    <SnackbarContent
      className={`${ classes.margin } ${ classes.snackbarContent }`}
      aria-describedby={ id }
      message={
        <span id={id } className={ classes.message }>
          <ErrorIcon className={`${ classes.icon } ${ classes.iconVariant }`} />
          { message }
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={ onClose }>
          <CloseIcon className={ classes.icon } />
        </IconButton>,
      ]}
    />
  </Snackbar>
);

export default compose(withState('id', 'setId', uuidv4), withStyles(styles))(ErrorSnackbar);
