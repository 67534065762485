import { Entity,
        ChoroplethEntry,
        ScatterPlotEntry,
        DataSource,
        Country,
        CausalData,
        BulletPlotData } from './model'

const API = process.env.REACT_APP_BACKEND_URL;

const LABEL_TO_INTEGER = {
  "Low": 0,
  "Medium-low": 1,
  "Medium": 2,
  "Medium-high": 3,
  "High": 4
}

export const COLOR_SCHEMA = {
  "Low": "#FFF5F0",
  "Medium-low": "#FDBBA1",
  "Medium": "#FB6A4A",
  "Medium-high": "#CB181D",
  "High": "#67000D"
}

export class ModelService {
  // Singleton pattern, so data can be shared between views
  private static instance: ModelService;

  private data: Entity[] = []
  private causaldata: Array<CausalData> = []
  private countries: Country[] = []
  private datasources: DataSource[] = []

  constructor() {
    if(ModelService.instance){
      throw new Error("Error: Instantiation failed: Use ModelService.getInstance() instead of new.");
    }
  }

  // singleton static method to get instance of service
  public static getInstance(): ModelService {
    ModelService.instance = ModelService.instance || new ModelService();
    return ModelService.instance;
  }

  // used to communicate with backend
  private async fetch(method: string, endpoint: string, body: string) {
    try {
      let header = {};

      if (method === 'GET') {
        header = {
          method,
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        };
      } else {
        header = {
          method,
          body: body && JSON.stringify(body),
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        };
      }

      const response = await fetch(`${API}/api${endpoint}`, header);

      if (response.ok && (response.status === 201 || response.status === 200)) {
        return await response.json();
      } else {
        throw new Error('Error communicating with backend');
      }
    }
    catch (error) {
      throw new Error(String(error));
    }
  }

  // helper functions to get currect columns depending on the visualization
  public getColumns(visualisation: string, monitorId?: string) {
    let dictionary: any = {}

    dictionary.ScatterPlot = {
      "Coastal_Flooding": ["CF_PI", "CF_Probability", "CF_Risk_Label"],
      "Droughts": ["DR_PI", "DR_Probability", "CF_Risk_Label"],
      "Heatwaves": ["HW_PI", "HW_Probability", "CF_Risk_Label"],
      "Landslides": ["LS_PI", "LS_Probability", "CF_Risk_Label"],
      "Riverine_Flooding": ["RF_PI", "RF_Probability", "CF_Risk_Label"],
      "Tropical_Storms": ["TS_PI", "TS_Probability", "CF_Risk_Label"],
      "Wildfires": ["WF_PI", "WF_Probability", "CF_Risk_Label"],
    }

    dictionary.Choropleth = {
      "Coastal_Flooding": "CF_Risk_Label",
      "Droughts": "DR_Risk_Label",
      "Heatwaves": "HW_Risk_Label",
      "Landslides": "LS_Risk_Label",
      "Riverine_Flooding": "RF_Risk_Label",
      "Tropical_Storms": "TS_Risk_Label",
      "Wildfires": "WF_Risk_Label"
    }

    dictionary.ToolTip = {
      "Coastal_Flooding": ["CF_Exposure", "CF_Hazard", "Vulnerability", "Susceptibility"],
      "Droughts": ["DR_Exposure", "DR_Hazard", "Vulnerability", "Susceptibility"],
      "Heatwaves": ["HW_Exposure", "HW_Hazard", "Vulnerability", "Susceptibility"],
      "Landslides": ["LS_Exposure", "LS_Hazard", "Vulnerability", "Susceptibility"],
      "Riverine_Flooding": ["RF_Exposure", "RF_Hazard", "Vulnerability", "Susceptibility"],
      "Tropical_Storms": ["TS_Exposure", "TS_Hazard", "Vulnerability", "Susceptibility"],
      "Wildfires": ["WF_Exposure", "WF_Hazard", "Vulnerability", "Susceptibility"],
    }

    dictionary.BulletPlot = {
      "Coastal_Flooding": "CF_Risk",
      "Droughts": "DR_Risk",
      "Heatwaves": "HW_Risk",
      "Landslides": "LS_Risk",
      "Riverine_Flooding": "RF_Risk",
      "Tropical_Storms": "TS_Risk",
      "Wildfires": "WF_Risk"
    }

    if(monitorId) {
      return dictionary[visualisation][monitorId]
    } else {
      return dictionary[visualisation]
    }
  }

  // returns data for visualizations
  private async getData(): Promise<Array<Entity>> {
    if(this.data.length === 0) {
      try {
        return this.fetch('GET', '/models', '').then((response: Array<Entity>) => {
          this.data = response
          return this.data;
        });
      }
      catch (error) {
        throw new Error(String(error));
      }
    } else {
      return this.data
    }
  }

  // groups data by case
  private groupCases(data: any) {
    return data.reduce((groups: any, item: any) => {
      const group = (groups[item.case] || []);
      group.push(item);
      groups[item.case] = group;
      return groups;
    }, {});
  }

  private async getCausalData(): Promise<Array<CausalData>> {
    // data not yet loaded from backend
    if(this.causaldata.length === 0) {
      let requests = [
        this.fetch('GET', '/causalmodels/edges', ''),
        this.fetch('GET', '/causalmodels/nodes', ''),
        this.fetch('GET', '/causalmodels/options', '')
      ]

      try {
        return Promise.all(requests)
        .then(response => {
          let output: CausalData[] = []
          let edges = response[0]
          let nodes = response[1]
          let options = response[2]

          edges = this.groupCases(edges)
          nodes = this.groupCases(nodes)
          options = this.groupCases(options)

          // Create object for each hazard including the edges and nodes
          Object.keys(edges).forEach(key => {
            if(options[key]) {
              options[key].forEach((element: { paths: string; }) => element.paths = eval(element.paths))

              output.push({
                case: key,
                edges: edges[key],
                nodes: nodes[key],
                options: options[key]
              })
            }
          })

          this.causaldata = output
          return this.causaldata;
        });
      }
      catch (error) {
        throw new Error(String(error));
      }
    } else {
      return this.causaldata
    }
  }

  // return the data for the given monitor
  public getNetworkData(monitorId: string): Promise<CausalData> {
    return this.getCausalData()
    .then(data => {
      return data.find(entry => entry.case === monitorId.replace("_Causal", "").toLowerCase()) || {} as CausalData
    })
  }

  // returns data depending on a given country
  public getCountryData(id: string): Entity {
    return this.data.filter(entity => entity["Country Code"] === id)[0]
  }

  // gets country depending on a given id
  public getCountry(id: string): Country {
    return this.countries.filter(country => country.iso3c === id)[0]
  }

  // gets countries from backend or returns local data if data already loaded
  public async getCountries(): Promise<Array<Country>> {
    if(this.countries.length === 0) {
      try {
        return this.fetch('GET', '/countries', '').then((response: Array<Country>) => {
          this.countries = response
          return this.countries;
        });
      }
      catch (error) {
        throw new Error(String(error));
      }
    } else {
      return this.countries
    }
  }

  private getRegionalAverages(hazards: string[], region: string) {
    let countryRegions = this.data.filter(country => country['Country Region'] === region)
    let hazardAverage: Record<string, number> = {};

    hazards.forEach(hazard => {
      hazardAverage[hazard] = countryRegions.reduce((sum, country) => sum + Number(country[hazard as keyof Entity]), 0) / countryRegions.length
    })

    return hazardAverage
  }

  // gets datasources from backend or returns local data if data already loaded
  public async getDataSources(): Promise<Array<DataSource>> {
    if(this.datasources.length === 0) {
      try {
        return this.fetch('GET', '/datasources', '').then((response: Array<DataSource>) => {
          response.forEach((item, i) => {
            item.id = i + 1;
          });

          this.datasources = response
          return this.datasources;
        });
      }
      catch (error) {
        throw new Error(String(error));
      }
    } else {
      return this.datasources
    }
  }

  private groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
    array.reduce((acc, value, index, array) => {
      (acc[predicate(value, index, array)] ||= []).push(value);
      return acc;
    }, {} as { [key: string]: T[] });

  // reformates the loaded data so it can be used in the scatter plot
  public async getScatterPlotData(monitorId: string): Promise<Array<ScatterPlotEntry>> {
    const columns = this.getColumns("ScatterPlot", monitorId)
    const x_column: keyof Entity = columns[0]
    const y_column: keyof Entity = columns[1]
    const risk_label_column: keyof Entity = columns[2]

    return this.getData()
    .then(data => {
      let output: ScatterPlotEntry[] = []
      let subdata: any[] = []

      data.forEach((element: Entity) => {
          const x = element[x_column]
          const y = element[y_column]
          const country = element["Country Code"]
          const risk_label = element[risk_label_column]

          subdata.push({risk_label, country, x, y})
      })

      const grouped = this.groupBy(subdata, v => v.risk_label)

      Object.keys(grouped).forEach(key => {
        output.push({id: key, data: grouped[key]})
      })

      return output
    })
  }

  // reformates the loaded data so it can be used in the  choropleth
  public async getChoroplethData(monitorId: string): Promise<Array<ChoroplethEntry>> {
    var result: ChoroplethEntry[] = []

    var column = this.getColumns("Choropleth", monitorId)
    var value: keyof Entity = column

    return this.getData()
    .then(data => {
      data.forEach((element: Entity) => {
        result.push(<ChoroplethEntry><unknown>({
          id: element["Country Code"],
          risk_label: element[value],
          // @ts-ignore
          value: LABEL_TO_INTEGER[element[value]],
        }))
      })

      return result
    })
  }

  public async getBulletPlotData(country: Country): Promise<Array<BulletPlotData>> {
    var result: BulletPlotData[] = []
    const columns = this.getColumns("BulletPlot")

    return this.getData()
    .then(() => {
      let countryData = this.getCountryData(country.iso3c)
      let regionalData = this.getRegionalAverages(Object.values(columns), countryData['Country Region'])

      Object.keys(columns).forEach((key: string) => {
        result.push(<BulletPlotData>({
          id: key,
          ranges: [
            0,
            100
          ],
          markers: [
            regionalData[columns[key as keyof typeof columns]]
          ],
          measures: [
            countryData[columns[key as keyof typeof columns] as keyof Entity]
          ]
        }))
      })

      return result
    })

    return result
  }
}
