import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
// @ts-ignore
import { compose, withState } from 'react-recompose';

const styles = () => ({
  root: {
    height: "15em",
    width: "9em"
  },
});

const ModelCard = ({
  modId,
  modTitle,
  description,
  imagePath,
  classes,
}: {
  modId: string;
  modTitle: string;
  description: string;
  imagePath: string;
  classes: any;
}) => (
    <Card className={ classes.root }>
      <CardActionArea>
        <CardMedia
          component="img"
          style={{
            width: "auto",
            margin: "auto",
            maxHeight: "100px"
          }}
          alt={`picture of model ${ modId }`}
          image={ imagePath }
        />

        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            { modTitle }
          </Typography>
          <Typography component="p">
            { description }
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
);

export default compose(
    withState('id', 'title', 'description', 'imagePath'),
    withStyles(styles),
)(ModelCard);