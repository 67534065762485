import { withStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
// @ts-ignore
import { compose } from 'react-recompose';

const styles = ({ spacing, palette }: Theme) => ({
  footer: {
    position: 'fixed',
    bottom: 0,
    margin: spacing(4)
  },
  text: {
    fontSize: "1em",
  }
});

const Footer = ({
  classes,
}: {
  classes: any;
}) => (
  <div className={ classes.footer }>
  </div>
);

// @ts-ignore
export default compose(withStyles(styles))(Footer);