import { DataSet } from 'vis-data';
import { Network } from "vis-network/standalone";
import React, { Component, createRef } from "react";
import { withStyles, 
  Theme
} from '@material-ui/core';
// @ts-ignore
import { compose } from 'react-recompose';
import { withRouter } from 'react-router-dom';

import { Edge, Node, CausalData, Option } from '../model';

const styles = ({ spacing }: Theme, props: any) => ({
  root: {
    width: '100%',
    height: (props: { height: any; }) => props.height,
    marginTop: spacing(2),
  }
})

const options = {
  edges: {
    arrows: {
      to: {
        enabled: true,
        type: 'arrow'
      }
    },
    length: 300, // Longer edges between nodes.
    color: {
      inherit: false
    }
  },
  physics: {
    stabilization: false,
      barnesHut: {
        springLength: 200,
      },
  },
  interaction: {
    dragNodes: true
  },
  /*
  layout: {
    hierarchical: {
        direction: "UD",
        sortMethod: "directed"
    }
  },
  */
};

type NetworkPlotProps = {
  classes: any;
  data: CausalData;
  option: Option;
  highlightPath: boolean;
  height: number;
  onClick: (element: string, value: string) => void;
};

type NetworkPlotState = {
  network: any;
  appRef: any;
  selectedNode: Node;
  selectedEdge: Edge;
  dataset: any;
};

class NetworkPlot extends Component<NetworkPlotProps, NetworkPlotState> {
  constructor(props: NetworkPlotProps) {
    super(props);

    this.state = {
      network: {},
      appRef: createRef(),
      selectedNode:{} as Node,
      selectedEdge:{} as Edge,
      dataset: {
        nodes: new DataSet(this.props.data.nodes),
        edges: new DataSet(this.props.data.edges)
      }
    }

    this.onElementClick = this.onElementClick.bind(this);
    this.highlightPathes = this.highlightPathes.bind(this);
    this.highlightStartEndNode = this.highlightStartEndNode.bind(this);
    this.resetView = this.resetView.bind(this);
  }

  componentDidMount() {
    let network = new Network(this.state.appRef.current, this.state.dataset, options)
    network.stabilize();
    network.on('click', this.onElementClick)
    
    this.setState({
      network: network
    })
  }

  componentDidUpdate() {
    this.resetView()
    this.highlightStartEndNode()

    // only highlight if necessary
    if (this.props.highlightPath) {
      this.highlightPathes()
    }
  }

  resetView() {
    // remove all colorings from the edges
    let edges: Edge[] = []
    edges = this.state.dataset.edges.get()
    edges.forEach((edge: Edge) => {
      // @ts-ignore
      delete edge.value
    })
    this.state.dataset.edges.updateOnly(edges)

    // remove all colorings from the nodes
    let nodes: Node[] = []
    nodes = this.state.dataset.nodes.get()
    nodes.forEach((node: Node) => {
      node.hidden = false
      node.color = '#82A0BC'
    })
    this.state.dataset.nodes.updateOnly(nodes)
  }

  highlightPathes() {
    if (this.props.option.paths && this.props.option.paths.length > 0) {
      // highlight pathes
      let updatedEdges: Edge[] = []
      let strNode: string[] = []
      this.state.dataset.edges.get().forEach((edge: Edge) => {
        this.props.option.paths.forEach((path: string[]) => {
          for( let i = 0; i < path.length - 1; i++) {
            // if the edge is part of the path
            if(edge.from === path[i] && edge.to === path[i+1] || edge.from === path[i+1] && edge.to === path[i]) {
              strNode.push(edge.from, edge.to)
              edge.value = this.props.option.causal_effect * 100
              updatedEdges.push(edge)
            }
          }
        })
      })

      this.state.dataset.edges.updateOnly(updatedEdges)
      
      // remove nodes which are not part of the causal path
      strNode = strNode.filter((item, index) => strNode.indexOf(item) === index);
      let nodes: Node[] = this.state.dataset.nodes.get({
        filter: function (item: { id: string; }) {
          return strNode.indexOf(item.id) === -1;
        }
      })
      nodes.forEach((node: Node) => {
        node.hidden = true
      });

      this.state.dataset.nodes.updateOnly(nodes)
    }
  }

  highlightStartEndNode() {
    if(this.props.option.treatment && this.props.option.outcome) {
      let nodes: Node[] = []

      // update treatment and outcome node color
      let treatment = this.state.dataset.nodes.get(this.props.option.treatment)
      if (this.props.option.causal_effect > 0) {
        treatment.color = "#AE1917"
        treatment.font = { color: "white" }
      } else {
        treatment.color = "#4E8341"
        treatment.font = { color: "white" }
      }
      nodes.push(treatment)

      let outcome = this.state.dataset.nodes.get(this.props.option.outcome)
      outcome.color = "#1a3761"
      outcome.font = { color: "white" }
      nodes.push(outcome)

      this.state.dataset.nodes.updateOnly(nodes)
    }
  }

  onElementClick(properties: any) {
    let object = ''
    let name = ''
    
    if(properties.nodes.length > 0) {
      name = "selectedNode"
      object = properties.nodes[0]
    }
    else if(properties.edges.length > 0) {
      name = "selectedEdge"
      object = properties.edges[0]
    }

    // @ts-ignore
    this.setState({[name]: object})
    this.props.onClick(name, object)
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={ classes.root } ref={ this.state.appRef } />
    );
  }
}

// @ts-ignore
export default compose(withRouter, withStyles(styles))(NetworkPlot);