import { withStyles,
        Typography,
        Theme,
        FormControl,
        InputLabel,
        MenuItem,
        Select,
        Input,
        Chip,
        Grid,
        TextField,
        Checkbox,
        ListItemText } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Component, Fragment } from 'react';
// @ts-ignore
import { compose } from 'react-recompose';
import { withRouter } from 'react-router-dom';

import ErrorSnackbar from '../components/errorSnackbar';
import InfoSnackbar from '../components/infoSnackbar';
import LoadingBar from '../components/loadingBar';
import ScatterPlot from '../components/scatterPlot';
import Choropleth from '../components/choropleth';
import BulletPlot from '../components/bulletPlot';

import { ModelService } from '../services';
import { ChoroplethEntry, ScatterPlotEntry, Country, BulletPlotData, ScatterPlotDataPoint } from '../model'

const styles = ({spacing}: Theme) => ({
  chips: {
    width: "400px"
  },
  formControl: {
    width: "400px",
    marginBottom: spacing(1),
    marginTop: spacing(0.5),
    paddingRight: spacing(1),
  }
});

const HAZARDS = [
  "Coastal_Flooding",
  "Droughts",
  "Heatwaves",
  "Landslides",
  "Riverine_Flooding",
  "Tropical_Storms",
  "Wildfires"
].sort();

const RISK_LEVELS = [
  "Low",
  "Medium-low",
  "Medium",
  "Medium-high",
  "High"
]

type MonitorViewerProps = {
  history: any;
  classes: any;
  location: any;
};

type MonitorViewerState = {
  monitorId: string;
  monitorReadable: string;
  countries: Country[];
  countries_selected: string[];
  riskLevels_selected: string[];
  scatterPlotData: ScatterPlotEntry[];
  scatterPlotData_selected: ScatterPlotEntry[];
  choroplethData: ChoroplethEntry[];
  choroplethData_selected: ChoroplethEntry[];
  bulletPlotData: BulletPlotData[];

  service: ModelService;

  loading: boolean;
  success: string;
  error: string;
};

class MonitorViewer extends Component<MonitorViewerProps, MonitorViewerState> {
  constructor(props: any) {
    super(props);

    this.state = {
      monitorId: '',
      monitorReadable: '',
      countries: [],
      countries_selected: [],
      riskLevels_selected: [],
      scatterPlotData: [],
      choroplethData: [],
      scatterPlotData_selected: [],
      choroplethData_selected: [],
      bulletPlotData: [],

      service: ModelService.getInstance(),

      loading: true,
      success: '',
      error: '',
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleRiskLevelChange = this.handleRiskLevelChange.bind(this);
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount = () => {
    let monitorId = "Coastal_Flooding";
    let monitorReadable = "Coastal Flooding";

    // get hazard from URL
    if(this.props.location.pathname.split("/").length > 2 &&  this.props.location.pathname.split("/")[2] !== "") {
      monitorId = this.props.location.pathname.split("/")[2]
      monitorReadable = monitorId.replaceAll("_", " ")      // create printable monitor name based on ID
    }

    this.setState({
      monitorId: monitorId,
      monitorReadable: monitorReadable
    }, () => {
      this.getMonitorData()
    })
  }

  async getMonitorData() {
    let scatterPlotData = await this.getScatterPlotData()
    let choroplethData = await this.getChoroplethData()
    let countries = await this.getCountries()

    this.setState({
      scatterPlotData: scatterPlotData,
      scatterPlotData_selected: scatterPlotData,
      choroplethData: choroplethData,
      choroplethData_selected: choroplethData,
      countries: countries,
      loading: false,
    });
  }

  async getCountries() {
    try {
      return await this.state.service.getCountries()
    }
    catch {
      this.setState({
        error: "Could not talk with backend"
      })

      return []
    }
  }

  async getScatterPlotData() {
    try {
      return await this.state.service.getScatterPlotData(this.state.monitorId)
    }
    catch {
      this.setState({
        error: "Could not talk with backend"
      })

      return []
    }
  }

  async getChoroplethData() {
    try {
      return await this.state.service.getChoroplethData(this.state.monitorId)
    }
    catch {
      this.setState({
        error: "Could not talk with backend"
      })

      return []
    }
  }

  handleCountryChange(evt: React.ChangeEvent<{ value: unknown }>) {
    this.setState({
      countries_selected: (evt.target.value as string[]).sort()
    }, () => {
      this.filterDataSet()
    })
  }

  handleRiskLevelChange(evt: React.ChangeEvent<{ value: unknown }>) {
    this.setState({
      riskLevels_selected: (evt.target.value as string[]).sort()
    }, () => {
      this.filterDataSet()
    })
  }

  handleHazardChange(hazard: string | null) {
    if(hazard) {
      this.setState({
        monitorId: hazard,
        monitorReadable: hazard.replaceAll("_", " "),      // create printable monitor name based on ID
      }, () => {
        this.getMonitorData()
        this.routingFunction(hazard)
      })
    } else {
      this.setState({
        monitorId: '',
        monitorReadable: '',
        countries_selected: [],
        scatterPlotData: [],
        choroplethData: [],
        scatterPlotData_selected: [],
        choroplethData_selected: [],
      })
    }
  }

  filterDataSet() {
    // this plot is only supported for one country
    if(this.state.countries_selected.length === 1) {
      this.state.service.getBulletPlotData({ name: "", iso3c: this.state.countries_selected[0]})
      .then(bulletPlotData => {
        this.setState({
          bulletPlotData: bulletPlotData,
        })
      })
    }

    let output: { data: ScatterPlotDataPoint[]; id: string; }[] = []

    this.state.scatterPlotData.forEach(element => {
      if (this.state.riskLevels_selected.length === 0) {
        output.push({
          ...element,
          data: element.data.filter(data => this.state.countries_selected.includes(data.country))
        })
      }

      if (this.state.countries_selected.length === 0 && this.state.riskLevels_selected.includes(element.id)) {
          output.push({
            ...element,
            data: element.data
          })
      }

      if(this.state.riskLevels_selected.includes(element.id)) {
        output.push({
          ...element,
          data: element.data.filter(data => this.state.countries_selected.includes(data.country))
        })
      }
    })

    this.setState({
      choroplethData_selected: this.state.choroplethData.filter(element => {
        if(this.state.countries_selected.length === 0) return this.state.riskLevels_selected.includes(element.risk_label)
        if(this.state.riskLevels_selected.length === 0) return this.state.countries_selected.includes(element.id)

        return this.state.countries_selected.includes(element.id) && this.state.riskLevels_selected.includes(element.risk_label)
      }),
      scatterPlotData_selected: output
    })
  }

  onClick(node: any) {
    if(!this.state.countries_selected.includes(node.id)) {
      let countries = this.state.countries_selected
      countries.push(node.id)

      this.setState({
        countries_selected: countries
      }, () => {
        this.filterDataSet()
      })
    } else {
      let countries = this.state.countries_selected.filter(element => element !== node.id)

      this.setState({
        countries_selected: countries
      }, () => {
        this.filterDataSet()
      })
    }
  }

  routingFunction = (param: string) => {
    this.props.history.push({
      pathname: "/hazardviewer/" + param,
      state: param
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h4">Hazard View</Typography>

         { /* Dropdown selection for filter */ }
          <FormControl className={ classes.formControl }>
            { /* inout for country */ }
            <Autocomplete
              id="hazard-selection"
              value={ this.state.monitorId }
              onChange={(event: any, hazard: string | null) => { this.handleHazardChange(hazard) }}
              options={ HAZARDS }
              className={ classes.formControl }
              getOptionLabel={ (option) => option.replace("_", " ") }
              renderInput={(params) => <TextField {...params} label="Hazard Selection" variant="outlined" />}
              />
          </FormControl>

         {this.state.monitorId && (
          // monitor data present
          <Fragment>
            <Typography variant="h5">{ this.state.monitorReadable }</Typography>

            <FormControl className={ classes.formControl }>
              <InputLabel id="country-selection-label">Countries</InputLabel>
              <Select
                labelId="country-selection-label"
                id="country-selection"
                multiple
                value={ this.state.countries_selected }
                onChange={ this.handleCountryChange }
                input={<Input id="select-multiple-countries" />}
                renderValue={(selected) => (
                  <div className={ classes.chips }>
                    {(selected as string[]).map((value) => (
                      <Chip key={ value } label={ value } />
                    ))}
                  </div>
                )}
              >
                {this.state.countries.map((country) => (
                  <MenuItem key={ country.iso3c } value={ country.iso3c }>
                    { country.name }
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="risk-level-label">Risk Level</InputLabel>
              <Select
                labelId="risk-level-label"
                id="risk-level"
                multiple
                value={this.state.riskLevels_selected}
                onChange={this.handleRiskLevelChange}
                input={<Input />}
                renderValue={(selected: any) => selected.join(', ')}
              >
                {RISK_LEVELS.map((riskLevel) => (
                  <MenuItem key={riskLevel} value={riskLevel}>
                    <Checkbox checked={this.state.riskLevels_selected.indexOf(riskLevel) > -1} />
                    <ListItemText primary={riskLevel} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Grid container spacing={ 2 }>
              {/* Cross Country Hazard View - only if one country is selected */}
              {this.state.countries_selected.length == 1 && (
                <Grid item xs={ 12 }>
                  <Typography variant="h6">Cross Hazard Country Plot</Typography>
                  <BulletPlot
                    data={ this.state.bulletPlotData }
                    id={ this.state.countries_selected[0] }
                  ></BulletPlot>
                </Grid>
              )}

              {/* Choropleth */}
              <Grid item xs={ 12 } sm={ 6 }>
                <Typography variant="h5">Total Risk of Insecurity</Typography>
                <Choropleth
                  hazard={ this.state.monitorId }
                  data={ this.state.choroplethData_selected }
                  onClick={ this.onClick }
                />
                <Typography>*Risk is measured as a function of the probability of a natural hazard to occur and the potential adverse impacts this hazard could cause within a society.</Typography>
                <Typography>*Risk = Impact * Probability</Typography>
              </Grid>

              {/* Scatterplot */}
              <Grid item xs={ 12 } sm={ 6 }>
              <Typography variant="h5">Probability and Impact on Insecurity</Typography>
                <ScatterPlot
                  hazard={ this.state.monitorId }
                  data={ this.state.scatterPlotData_selected }
                  onClick={ this.onClick }
                />
                <Typography>*Probability refers to the likelihood of a (natural) disaster to occur.</Typography>
                <Typography>*Impact refers to the prospective adverse consequences of future climaterelated disasters on natural and human systems</Typography>
              </Grid>
            </Grid>
          </Fragment>
        )}

        {/* Flag based display of loadingbar */}
        {this.state.loading && <LoadingBar />}

        {/* Flag based display of error snackbar */}
        {this.state.error.length > 0 && (
          <ErrorSnackbar onClose={() => this.setState({ error: '' })} message={this.state.error} />
        )}

        {/* Flag based display of info snackbar */}
        {this.state.success.length > 0 && (
          <InfoSnackbar
            onClose={ () => this.setState({ success: '' }) }
            message={ this.state.success }
          />
        )}
      </Fragment>
    );
  }
}

// @ts-ignore
export default compose(withRouter, withStyles(styles))(MonitorViewer);
